var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1 tabela-modelos",attrs:{"headers":_vm.headers,"items":_vm.itens,"items-per-page":10,"page":_vm.pageNumber,"locale":"pt-BR","loading-text":"Carregando","no-data-text":"Nenhum dado encontrado","loading":_vm.loading,"server-items-length":_vm.total,"footer-props":{
    'items-per-page-text': 'Modelos por página:',
    'items-per-page-all-text': 'Todos',
    'show-first-last-page': true,
    'show-current-page': true,
    'page-text': _vm.pageText,
  }},on:{"update:options":_vm.handleFilter},scopedSlots:_vm._u([{key:`body.prepend`,fn:function(){return [_c('tr',[_vm._l((_vm.headers),function(header,index){return [_c('th',{key:index},[_vm._t(header.value)],2)]})],2)]},proxy:true},{key:`item.produtos`,fn:function({ item }){return _vm._l((item.produtos),function(produto){return _c('div',{key:produto.id},[_vm._v(" "+_vm._s(produto.nome)+" ")])})}},{key:`item.categorias`,fn:function({ item }){return _vm._l((item.categorias),function(categoria){return _c('div',{key:categoria.id},[_vm._v(" "+_vm._s(categoria.nome)+" ")])})}},{key:`item.tags`,fn:function({ item }){return _vm._l((item.tags),function(tag){return _c('div',{key:tag.id},[_c('v-chip',{staticClass:"ma-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(tag.nome)+" ")])],1)})}},{key:`item.id`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":""},on:{"click":function($event){return _vm.$emit('selecionar', item)}}},[_vm._v(" Selecionar ")])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }